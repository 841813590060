import createApiConstants from '../utils/createApiConstants';

const PAGE_NAME = 'GLOBAL';
const createConstants = (...args) => createApiConstants(PAGE_NAME, ...args);

export const authAsUserConstants = createConstants('AUTH_ADMIN', [
    'LOGIN',
    'LOGIN_SUCCESS',
    'LOGIN_ERROR',
    'LOGIN_PARTNER',
    'LOGIN_PARTNER_SUCCESS',
    'LOGIN_PARTNER_ERROR',
]);

export const authConstants = createConstants('AUTH', [
    'REFRESH_LOGIN',
    'REFRESH_LOGIN_SUCCESS',
    'REFRESH_LOGIN_ERROR',
    'LOGIN',
    'LOGIN_SUCCESS',
    'LOGIN_ERROR',
    'LOGOUT',
    'LOGOUT_SUCCESS',
    'CHANGE_PASSWORD',
    'CHANGE_PASSWORD_SUCCESS',
    'CHANGE_PASSWORD_ERROR',
    'READ_USER',
    'READ_USER_SUCCESS',
    'ACTIVATE',
    'ACTIVATE_SUCCESS',
    'ACTIVATE_ERROR',
    'UPDATE_USER',
    'UPDATE_USER_SUCCESS',
    'UPDATE_USER_ERROR'
]);

export const tariffsConstants = createConstants('TARIFFS');
export const usersConstants = createConstants('USERS');

const reduxConstants = {
    auth: authConstants,
    tariffs: tariffsConstants,
    users: usersConstants
};

export default reduxConstants;