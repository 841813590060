import createApiReducer from '../utils/createApiReducer';
import { usersConstants } from '../constants/reduxConstants';

export const initialState = {
    loading: false,
    error: false,
    payload: [],
};

const actionHandler = {
    [usersConstants.UPDATE_SUCCESS] : (state,{payload, initPayload})=> {
      const users = state.payload.slice();
      const index = users.findIndex(user=>user._id === initPayload._id);
      if(index !== -1) {
        users[index] = {...users[index], ...initPayload}
      }
      return {
        ...state,
        loading: false,
        error: false,
        payload: users
      }
    }
  }
const usersReducer = createApiReducer(initialState, usersConstants, actionHandler);

export default usersReducer;