import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import {show, swal, close} from './actions';
import { makeSelectSweetAlert } from './selectors';
import reducer from './reducer';
import saga from './saga';
import SweetAlert from './SweetAlert';

const mapDispatchToProps = (dispatch) => ({
    swal: payload=> dispatch(swal(payload))
});

const mapStateToProps = createStructuredSelector({
    sweetAlert: makeSelectSweetAlert()
});

const withReducer = injectReducer({ key: 'sweetAlert', reducer });
const withSaga = injectSaga({ key: 'sweetAlert', saga });

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withReducer, withSaga, withConnect)(SweetAlert);
export { 
    show, 
    swal, 
    close, 
    makeSelectSweetAlert as makeSelect };