import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import authActions from 'api/actions/auth';
import selectUser from 'api/selectors/user';
import Header from './Header';

const mapDispatchToProps = (dispatch) => ({
  onLogout: (payload) => dispatch(authActions.logout(payload)),
})

const mapStateToProps = createStructuredSelector({
    user: selectUser
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);