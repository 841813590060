/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */

import React from 'react';
import { Link } from 'react-router-dom';
import Cat from 'assets/images/cat.png';

export default class NotFoundPage extends React.PureComponent {
  render() {
    return (
      <div className="page-error pt-0">
        <div className="page-inner">
          <h1>
            <img className="img-fluid" src={Cat} alt='404'/>
          </h1>
          <h3>К сожалению, эта страница недоступна.</h3>
          <div className="page-description">
            Возможно вы воспользовались недействительной ссылкой или страница была удалена.
            <div className="mt-3">
              <Link to='/'>
                Перейти на главную.
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}