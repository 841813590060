import get from 'lodash/get';
import has from 'lodash/has';
import invariant from 'invariant';
import { createSelector } from 'reselect';

const defaultSelector = (state) => state;

export const makeSelect = (key, initSelector= defaultSelector) => {
    const selector = (state)=> {
        state = initSelector(state);

        const selected = get(state,key);
        
        if (process.env.NODE_ENV === `development`) {
            invariant(
                has(state, key),
                `(api/utils...) makeSelector: selected state by path: { ${key} } from initSelector: { ${initSelector} } not found`
            );
        }
        return selected;
    }

    return selector;
}

export const makeSelectLoading = (...keys) => {
    const keysLength = keys.length;
    const selectors = [];
    for (let i = 0; i < keysLength; i++) {
        const key = keys[i];
        const selector = (state)=> {
            const selected = get(state,key)||{};
            if(process.env.NODE_ENV === `development`) {
                invariant(
                    has(selected, 'loading'),
                    `(api/utils...) makeSelector: selected state by path: { ${key} } not found property loading`
                );
            }
            return selected.loading;
        }
        selectors.push(selector)
    };

    return createSelector(
        [...selectors],
        (...selectedState) => {
            const l = selectedState.length;
            for (let i = 0; i < l; i++) {
                if(selectedState[i]) {
                    return true;
                }
            }
            return false;
        }
    );
}

export const makeSelectError = (...keys) => {
    const keysLength = keys.length;
    const selectors = [];
    for (let i = 0; i < keysLength; i++) {
        const key = keys[i];
        const selector = (state)=> {
            const selected = get(state,key)||{};
            if(process.env.NODE_ENV === `development`) {
                invariant(
                    has(selected, 'error'),
                    `(api/utils...) makeSelector: selected state by path: { ${key} } not found property error`
                );
            }
            return selected.error;
        }
        selectors.push(selector)
    };

    return createSelector(
        [...selectors],
        (...selectedState) => {
            const l = selectedState.length;
            for (let i = 0; i < l; i++) {
                if(selectedState[i]) {
                    return true;
                }
            }
            return false;
        }
    );
}