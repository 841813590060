import { call, put, select, race, take } from 'redux-saga/effects';
import request from 'utils/request';

import authActions from '../actions/auth';

import { authConstants } from '../constants/reduxConstants';
import selectTokens from '../selectors/tokens';

/**
 * API request/response handler
 */

export default function* requestBearer(requestURL, options = {}) {
    // Select tokens from store
    const tokens = yield select(selectTokens);

    options.headers = new Headers({
        ...options.headers,
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + tokens.access_token
    })

    try {
        const data = yield call(request, requestURL, options);
        return data;
    } catch (err) {
        if (err) {
            const status = ((err || {}).response || {}).status;
            if(status === 401) {
                yield put(authActions.refreshLogin(tokens.refresh_token));
                const isLogin = yield race({
                    success: take(authConstants.REFRESH_LOGIN_SUCCESS),
                    error: take(authConstants.REFRESH_LOGIN_ERROR),
                })
                if(isLogin.success) {
                    try {
                        // const newTokens = yield select(selectTokens);
                        const newTokens = isLogin.success.payload;
                        options.headers = new Headers({
                            ...options.headers,
                            'Authorization': 'Bearer ' + newTokens.access_token
                        })
                        const Data = yield call(request, requestURL, options);
                        return Data;
                    } catch (error) {
                        throw error;
                    }
                } else {
                    throw isLogin.error.payload.error
                }
            } else {
                throw err;
            }
        } else {
            throw new Error(`Неудачный запрос requestBearer по url:${requestURL}`);
        }
    }
}