import reduxConstants from '../constants/reduxConstants';
import createApiActions from '../utils/createApiActions';

import authActions from './auth';
import authAsUserActions from './authAsUser';

const allActions = {
    auth: authActions,
    authAdmin: authAsUserActions
};
// eslint-disable-next-line
for (const key in reduxConstants) {
    if(!allActions[key]) {
        allActions[key] = createApiActions(reduxConstants[key])
    }
}

export default allActions;