import React, { useContext } from 'react';
import classNames from 'classnames';
import BootstrapNavbarCollapse from 'react-bootstrap/NavbarCollapse';
import NavbarContext from 'react-bootstrap/NavbarContext';
import { useBootstrapPrefix } from 'react-bootstrap/ThemeProvider';
import OffCanvasContext from 'components/OffCanvas/OffCanvasContext';
import './navbar.scss';

const NavbarCollapse = React.forwardRef(({ bsPrefix, className, children, ...props }, ref) => {
    const navbarContext = useContext(NavbarContext);
    const offCanvasContext = useContext(OffCanvasContext);
    const prefix = useBootstrapPrefix(bsPrefix, 'offcanvas');
    if(offCanvasContext.scroller) {
        if(navbarContext.expanded) {
            className = classNames(prefix, 'open', className);
        } else {
            className = classNames(prefix, className);
        }
    }
    return (
        <BootstrapNavbarCollapse
            ref={ref}
            {...props}
            className={className}
        >
            {children}
        </BootstrapNavbarCollapse>
    );
})

const name = BootstrapNavbarCollapse.displayName || BootstrapNavbarCollapse.name;
NavbarCollapse.displayName = `withOffCanvas(${name})`;
export default NavbarCollapse;