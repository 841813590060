/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import globalReducer from 'api/reducer/global.js';
import globalAdminReducer from 'api/reducer/index.js';
/**
 * Creates the main reducer with the dynamically injected ones
 */

let routerReducer = null;

export default function createReducer(injectedReducers, history) {
  if(!routerReducer) routerReducer = connectRouter(history);
  return combineReducers({
    router: routerReducer,
    globalAdmin: globalAdminReducer,
    global: globalReducer,
    ...injectedReducers,
  });
}
