import { combineReducers } from 'redux';
import createApiReducer from '../utils/createApiReducer';
import reduxConstants from '../constants/reduxConstants';

import authReducer from './auth';
import usersReducer from './users'

const globalReducer = {
  auth: authReducer,
  users: usersReducer
};

// The initial state for global
export const initialState = {
    tariffs: {
        loading: false,
        error: false,
        payload: [],
    },
};

// eslint-disable-next-line
for (const key in reduxConstants) {
    if(!globalReducer[key]) {
        globalReducer[key] = createApiReducer(initialState[key], reduxConstants[key]);
    }
}

export default combineReducers(globalReducer);