import createApiReducer from '../utils/createApiReducer';
import { authAsUserConstants } from '../constants/reduxConstants';

export const initialState = {
    loading: false,
    error: false,
    isAuth: false,
    payload: {
      clo: {
        access_token: null,
        refresh_token: null,
        user: {
          loading: false,
          tariff: {
              variant: null,
              expires: Date.now()
          }
        }
      }
    },
};

const actionHandler = {
  [authAsUserConstants.LOGIN]: (state,action) => {
    return {
      ...state,
      error: false,
      loading: true
    }
  },
  [authAsUserConstants.LOGIN_SUCCESS]: (state,{payload}) => {
    return {
      ...state,
      error: false,
      isAuth: true,
      loading: false,
      payload
    }
  },
  [authAsUserConstants.LOGIN_ERROR]: (state,{error}) => {
    return {
      ...initialState,
      loading: false,
      isAuth: false,
      error,
    }
  },
};

const authReducer = createApiReducer(initialState, authAsUserConstants, actionHandler);

export default authReducer;