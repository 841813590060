import { take, race, call, put, takeLeading, all } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import requestBasic from '../utils/requestBasic';
import authActions from '../actions/authAsUser';
import { authAsUserConstants } from '../constants/reduxConstants';
import apiConstants from '../constants/apiConstants';
import { headersJson } from '../utils/createApiSaga';
import * as sweetAlert from 'components/SweetAlert/actions';

const actionChannel = channel();

function* watchActionChannel() {
    while (true) {
        const action = yield take(actionChannel);
        yield put(action);
    }
}

export function* login(action) {
    try {
        const url = apiConstants.adminLogin;
        const options = {
            method: 'POST',
            headers: headersJson,
            body: JSON.stringify({
                grant_type: 'password',
                username: action.payload.username,
                password: 'password',
            })
        }
        const data = yield call(requestBasic, url, options);

        yield put(authActions.loginPartner(action.payload));

        const partnerRequest = yield race({
            success: take(authActions.loginPartnerSuccess),
            error: take(authActions.loginPartnerError),
        })
        if(partnerRequest.error) {
            throw partnerRequest.error.payload;
        }
        yield put(authActions.loginSuccess({
            clo: data,
            partner: partnerRequest.success.payload
        }));

    } catch (error) {
        yield put(authActions.loginError(error));
        yield put(sweetAlert.swal({
            type: 'danger',
            message: error.message,
        }));
    }
}

export function* loginPartner(action) {
    try {
        const url = apiConstants.adminPartnerLogin;
        const options = {
            backend: 'partner',
            method: 'POST',
            headers: headersJson,
            body: JSON.stringify({
                grant_type: 'password',
                username: action.payload.username,
                password: 'password',
            })
        }
        const data = yield call(requestBasic, url, options);
        yield put(authActions.loginPartnerSuccess(data));
    } catch (error) {
        yield put(authActions.loginPartnerSuccess({        
            access_token: null,
            refresh_token: null,
            user: {
              access: {},
              stats: {
                money: {},
              },
              settings: {
                payment: [],
              },
              domains: [],
              ips: [],
              offers: [],
              favoriteOffers: [],
              loading: false,
            }
        }));
    }
}

export function* loginSuccess({payload}) {

    const domain = window.location.hostname.split('.').slice(-2).join('.');

    const cookieData = {
        clo: {
            access_token: payload.clo.access_token,
            refresh_token: payload.clo.refresh_token,
        },
        partner: {
            access_token: payload.partner.access_token,
            refresh_token: payload.partner.refresh_token,
        },
    }
    const searchStr = '?auth='+encodeURIComponent(JSON.stringify(cookieData))

    yield window.open(`//${domain}${searchStr}`, '_blank');
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* authAdminData() {
    yield all([
        watchActionChannel(),
        takeLeading(authAsUserConstants.LOGIN, login),
        takeLeading(authAsUserConstants.LOGIN_SUCCESS, loginSuccess),
        takeLeading(authAsUserConstants.LOGIN_PARTNER, loginPartner),
    ])
}