import { call } from 'redux-saga/effects';
import request from 'utils/request';
import { CLIENT_USERNAME, CLIENT_PASSWORD, PARTNER_CLIENT_PASSWORD, PARTNER_CLIENT_USERNAME } from '../constants/apiConstants';

/**
 * API request/response handler
 */

// export default function* requestBasic(requestURL, options = {}) {

//     options.headers = new Headers({
//         'Content-Type': 'application/json',
//         'Authorization': 'Basic ' + btoa(CLIENT_USERNAME + ":" + CLIENT_PASSWORD),
//         ...options.headers
//     })

//     try {
//         // Call our request helper (see 'utils/request')
//         const data = yield call(request, requestURL, options);
//         return data;
//     } catch (err) {
//         throw err;
//     }
// }

export default function* requestBasic(requestURL, {backend, ...options}) {
    let password, username;

    if(backend === 'partner') {
        password = PARTNER_CLIENT_PASSWORD;
        username = PARTNER_CLIENT_USERNAME;
    } else {
        password = CLIENT_PASSWORD;
        username = CLIENT_USERNAME;
    }

    options.headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + btoa(username + ":" + password),
        ...options.headers
    })

    try {
        const data = yield call(request, requestURL, options);
        return data;
    } catch (err) {
        throw err;
    }
}
