import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

export default class ReduxSweetAlert extends Component {
  static propTypes = {
    sweetAlert: PropTypes.shape({
      show: PropTypes.bool.isRequired,
    }).isRequired,
  };

  render() {
    const {message, ...props} = this.props.sweetAlert;
    return (
      <SweetAlert {...props}>
        {message}
      </SweetAlert>
    );
  }
}