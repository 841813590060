import React from 'react';
import LoadingIndicator from '../LoadingIndicator';
import ErrorPage from '../ErrorPage';

export default function MyLoadingComponent({ error, pastDelay }) {
    if (error) {
        console.error(error);
        return <ErrorPage error={error}/>;
    } else if (pastDelay) {
        return <LoadingIndicator/>;
    } else {
        return null;
    }
}