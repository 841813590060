const DEFAULT_HANDLERS = {
    CREATE: (state)=> {
        return { 
            ...state,
            loading: true,
            error: false,
        }
    },
    CREATE_SUCCESS: (state, {payload})=> {
        return {
            ...state,
            loading: false,
            payload: payload
        }
    },
    CREATE_ERROR:  (state, {payload})=> {
        return { 
            ...state,
            loading: false,
            error: payload
        }
    },
    READ: (state)=> {
        return { 
            ...state,
            loading: true,
            error: false,
        }
    },
    READ_SUCCESS: (state, {payload})=> {
        return { 
            ...state,
            loading: false,
            payload: payload
        }
    },
    READ_ERROR:  (state, {payload})=> {
        return { 
            ...state,
            loading: false,
            error: payload
        }
    },
    UPDATE: (state)=> {
        return { 
            ...state,
            loading: true,
            error: false,
        }
    },
    UPDATE_SUCCESS: (state, {payload})=> {
        return { 
            ...state,
            loading: false,
            payload: payload
        }
    },
    UPDATE_ERROR:  (state, {payload})=> {
        return { 
            ...state,
            loading: false,
            error: payload
        }
    },
    DELETE: (state)=> {
        return { 
            ...state,
            loading: true,
            error: false,
        }
    },
    DELETE_SUCCESS: (state, {payload})=> {
        return { 
            ...state,
            loading: false,
            payload: payload
        }
    },
    DELETE_ERROR:  (state, {payload})=> {
        return { 
            ...state,
            loading: false,
            error: payload
        }
    },
}

// Принимает начальное состояние для state, ассоциативный массив констант, и ACTION_HANDLER
export default function createApiReducer(initialState={}, CONSTANTS={}, EXTENDS_ACTION_HANDLER={}) {

    DEFAULT_HANDLERS.CLEAR = ()=>initialState;

    const ACTION_HANDLER = {
        ...EXTENDS_ACTION_HANDLER
    };
// eslint-disable-next-line
    for (const key in CONSTANTS) {
        const CONSTANT = CONSTANTS[key];
        if(!ACTION_HANDLER[CONSTANT]) {
            ACTION_HANDLER[CONSTANT] = DEFAULT_HANDLERS[key];
        }
    }
    
    return function extendReducer(state=initialState, action){
        const handler = ACTION_HANDLER[action.type];
        return handler ? handler(state, action) : state;
    }
}