import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectLocation } from 'containers/App/selectors';
import selectAuth  from 'api/selectors/auth';
import App from './App';

const mapDispatchToProps = (dispatch) => ({
//   onChangeUsername: (evt) => dispatch(changeUsername(evt.target.value)),
//   onSubmitForm: (evt) => {
//     if (evt !== undefined && evt.preventDefault) evt.preventDefault();
//     dispatch(loadRepos());
//   }
});

const mapStateToProps = createStructuredSelector({
  location: selectLocation,
  auth: selectAuth
});


export default connect(mapStateToProps, mapDispatchToProps)(App);