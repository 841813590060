import createApiReducer from '../utils/createApiReducer';
import { authConstants } from '../constants/reduxConstants';

export const initialState = {
    loading: false,
    error: false,
    isAuth: false,
    payload: {
        access_token: null,
        refresh_token: null,
        user: {
          loading: false,
          tariff: {
              variant: null,
              expires: Date.now()
          } 
        }
    },
};

const actionHandler = {
  [authConstants.REFRESH_LOGIN]: (state,action) => {
    return {
      ...state,
      error: false,
      // loading: true
    }
  },
  [authConstants.LOGIN]: (state,action) => {
    return {
      ...state,
      error: false,
      loading: true
    }
  },
  [authConstants.LOGIN_SUCCESS]: (state,{payload}) => {
    return {
      ...state,
      error: false,
      isAuth: true,
      loading: false,
      payload
    }
  },
  [authConstants.LOGIN_ERROR]: (state,{error}) => {
    return {
      ...initialState,
      loading: false,
      isAuth: false,
      error,
    }
  },
  [authConstants.REFRESH_LOGIN_SUCCESS]: (state,{payload}) => {
    return {
      ...state,
      error: false,
      isAuth: true,
      loading: false,
      payload
    }
  },
  [authConstants.REFRESH_LOGIN_ERROR]: (state,{error}) => {
    return {
      ...initialState,
      loading: false,
      isAuth: false,
      error,
    }
  },
  [authConstants.READ_USER_SUCCESS]: (state,{payload}) => {
    return {
      ...state,
      payload: {
        ...state.payload,
        user: payload
      }
    }
  },
  [authConstants.UPDATE_USER]: (state,{payload}) => {
    const statePayload = state.payload;
    return {
      ...state,
      payload: {
        ...statePayload,
        user: {
          ...statePayload.user,
          loading: true
        } 
      }
    }
  },
  [authConstants.UPDATE_USER_SUCCESS]: (state,{payload}) => {
    const statePayload = state.payload;
    return {
      ...state,
      payload: {
        ...statePayload,
        user: {
          ...statePayload.user,
          ...payload,
          loading: false
        } 
      }
    }
  },
  [authConstants.UPDATE_USER_ERROR]: (state,{error}) => {
    const statePayload = state.payload;
    return {
      ...state,
      payload: {
        ...statePayload,
        user: {
          ...statePayload.user,
          loading: false,
          error
        } 
      }
    }
  },
  [authConstants.LOGOUT]: (state) => state,
  [authConstants.LOGOUT_SUCCESS]: () => initialState
};

const authReducer = createApiReducer(initialState, authConstants, actionHandler);

export default authReducer;