const defaultAuth =  {
    isAuth: false,
    loading: false,
    error: false,
    payload: {
        access_token: null,
        refresh_token: null,
        user: {
            access: {},
            tariff: {}
        }
    },
};

const initialStorage = {
    auth: {...defaultAuth},
    authAdmin: {...defaultAuth},
};

export const loadState = (key) => {
    try {
        const serializedState = localStorage.getItem(key);
        if (serializedState === null) {
            return initialStorage[key]
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return initialStorage[key]
    }
};

export const saveState = (key, state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(key, serializedState);
    } catch (err) {
        // Ignore write errors.
    }
};