import React from 'react';
import Container from 'components/Container';
import Row from 'components/Row';
import Col from 'components/Col';
import Nav from 'components/Nav';

import './style.scss';

const Footer = React.memo(({fluid}) => (
  <footer className="main-footer footer">
    <Container fluid={fluid}>
      <Row className="align-items-center justify-content-md-between">
        <Col md={6}>
          <div className="copyright text-center text-md-left text-muted">

            Copyright ©  {(new Date()).getFullYear()}
            <a className="font-weight-bold ml-1" href="https://vk.com/dzhaweb" target="_blank" rel="noopener noreferrer" >Vkontakte</a>
            {`  `}
            <a className="font-weight-bold ml-1" href="https://tele.click/dzhaclo" target="_blank" rel="noopener noreferrer" >Telegram</a>
          </div>
        </Col>
        <Col md={6}>
          <Nav className="nav-footer justify-content-center justify-content-md-end">
            <Nav.Item>
              <Nav.Link href="https://tele.click/dzhaweb" target="_blank" rel="noopener noreferrer">
                По всем вопросам
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://tele.click/HumanAnother" target="_blank" rel="noopener noreferrer">
                Тех. поддержка
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
    </Container>
  </footer>
));

export default Footer;
