import { call, put, select, takeLeading, all } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { swal } from 'components/SweetAlert';
import requestBasic from '../utils/requestBasic';
import requestBearer from '../utils/requestBearer';
import authActions from '../actions/auth';
import { authConstants } from '../constants/reduxConstants';
import apiConstants from '../constants/apiConstants';
import selectUser from '../selectors/user';
import selectTokens from '../selectors/tokens';
import { headersJson } from '../utils/createApiSaga';


export function* refreshLogin(action) {
    try {
        // Select tokens from store
        const {refresh_token} = yield select(selectTokens);
        // const token = action.payload.token;
        if(!refresh_token) {
            throw new Error('Не найден refresh_token');
        }
        const user = yield call(requestBasic, apiConstants.login, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                grant_type: 'refresh_token',
                refresh_token: refresh_token
            })
        })
        yield put(authActions.refreshLoginSuccess(user));
    } catch (error) {
        yield put(authActions.refreshLoginError(error));
    }
}

export function* login(action) {
    try {
        const user = yield call(requestBasic, apiConstants.login, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                grant_type: 'password',
                username: action.payload.username,
                password: action.payload.password,
            })
        })
        yield put(authActions.loginSuccess(user));
    } catch (error) {
        yield put(authActions.loginError(error));
    }
}

export function* logout() {
    const tokens = yield select(selectTokens);
    const user =  yield select(selectUser);
    try {
        yield call(requestBasic, apiConstants.logout, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...tokens, userId: user._id})
        })
    } catch (error) {}
    yield put(authActions.logoutSuccess());
    window.location = '/login';
}

export function* loginSuccess() {
    yield put(push('/'));
}

export function* changePassword({payload}) {
    try {
        const res = yield call(requestBasic, apiConstants.changePassword, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
        yield put(authActions.changePasswordSuccess(res));
    } catch (error) {
        const response = error.response ||{};
        yield put(swal({
            type: 'danger',
            title: response.message || response.error_description,
        }));
        // window.location = '/login';
    }
}

export function* changePasswordSuccess({payload}) {
    yield put(authActions.login(payload));
}

export function* readUser({payload}) {
    try {
        const data = yield call(requestBearer, apiConstants.user, { method: 'GET' });
        yield put(authActions.readUserSuccess(data));
    } catch (error) {}
}

export function* activate() {
    try {
        const data = yield call(requestBearer, apiConstants.activate, { method: 'GET' });
        yield put(authActions.activateSuccess(data));
    } catch (error) {
        yield put(authActions.activateError(error));
    }
}

export function* activateSuccess() {
    try {
        const user =  yield select(selectUser);
        yield put(swal({
            type: 'success',
            title: '',
            message: `Письмо с сылкой для активации было отправлено по адресу ${user.username}, если письмо не пришло проверьте папку спам.`,
        }));
    } catch (error) {
        console.error(error);
    }
}

export function* activateError({payload}) {
    try {
        const response = payload.response ||{};
        yield put(swal({
            type: 'danger',
            title: response.message || response.error_description,
        }));
    } catch (error) {
        console.error(error);
    }
}

export function* updateUser(action) {
    try {
        const data = yield call(requestBearer, apiConstants.user, { 
            method: 'PUT',
            headers: headersJson,
            body: JSON.stringify(action.payload)
        });
        yield put(authActions.updateUserSuccess(data));
    } catch (error) {
        yield put(authActions.updateUserError(error));
    }
}

export function* updateUserSuccess(action) {
    try {
        yield put(swal({
            type: 'success',
            title: 'Данные обновлены'
        }));
    } catch (error) {
        console.error(error);
    }
}

export function* updateUserError({payload}) {
    try {
        const response = payload.response ||{};
        yield put(swal({
            type: 'danger',
            title: response.message || response.error_description,
        }));
    } catch (error) {
        console.error(error);
    }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* authData() {
    yield all([
        takeLeading(authConstants.LOGIN, login),
        takeLeading(authConstants.LOGOUT, logout),
        takeLeading(authConstants.REFRESH_LOGIN, refreshLogin),
        takeLeading(authConstants.LOGIN_SUCCESS, loginSuccess),
        takeLeading(authConstants.CHANGE_PASSWORD, changePassword),
        takeLeading(authConstants.CHANGE_PASSWORD_SUCCESS, changePasswordSuccess),
        takeLeading(authConstants.READ_USER, readUser),
        takeLeading(authConstants.UPDATE_USER, updateUser),
        takeLeading(authConstants.UPDATE_USER_SUCCESS, updateUserSuccess),
        takeLeading(authConstants.UPDATE_USER_ERROR, updateUserError),
        takeLeading(authConstants.ACTIVATE, activate),
        takeLeading(authConstants.ACTIVATE_SUCCESS, activateSuccess),
        takeLeading(authConstants.ACTIVATE_ERROR, activateError),
    ])
}