import { call, put, takeLeading, takeLatest, all } from 'redux-saga/effects';
import iziToast from 'izitoast';
import querystring from 'querystring';

import requestBearer from './requestBearer';

export const headersJson = {
    'Content-Type': 'application/json'
};

const iziToastSave = {
    position: 'bottomRight',
    title: 'Успешно',
    message: 'Данные сохранены',
};

const iziToastUpdate = {
    position: 'bottomRight',
    title: 'Успешно',
    message: 'Данные обновлены',
};

const iziToastDelete = {
    position: 'bottomRight',
    title: 'Успешно',
    message: 'Данные удалены',
};

export default function createApiSaga(CONSTANTS, API, ACTIONS, params={}) {
    const result = {};
    if (CONSTANTS.CREATE) {
        result.create = takeLeading(CONSTANTS.CREATE, function* create(action) {
            try {
                const data = yield call(requestBearer, API, {
                    method: 'POST',
                    headers: headersJson,
                    body: JSON.stringify(action.payload)
                })
                
                iziToast.success(iziToastSave);
                yield put(ACTIONS.createSuccess(data, action.payload));
                action.callback && action.callback();
            } catch (error) {
                yield put(ACTIONS.createError(error));
                action.callback && action.callback(error);
            }
        })
    }

    if (CONSTANTS.READ) {
        result.read = takeLatest(CONSTANTS.READ, function* read(action) {
            try {
                const params = action.payload ? action.payload : undefined;
                let url = API;
                if (params) {
                    const query = querystring.stringify(params);
                    url += `?${query}`;
                }
                const data = yield call(requestBearer, url, { method: 'GET' });
                yield put(ACTIONS.readSuccess(data, action.payload));
                action.callback && action.callback();
            } catch (error) {
                yield put(ACTIONS.readError(error));
                action.callback && action.callback(error);
            }
        })
    }

    if (CONSTANTS.UPDATE) {
        result.update = takeLeading(CONSTANTS.UPDATE, function* update(action) {
            try {
                const data = yield call(requestBearer, API, {
                    method: 'PUT',
                    headers: headersJson,
                    body: JSON.stringify(action.payload)
                });
                iziToast.success(iziToastUpdate);
                const initPayload = action.payload;
                if(typeof data === typeof action.payload) {
                    action.payload = data; 
                };
                yield put(ACTIONS.updateSuccess(action.payload, initPayload));
                action.callback && action.callback();
            } catch (error) {
                yield put(ACTIONS.updateError(error));
                action.callback && action.callback(error);
            }
        })
    }

    if (CONSTANTS.DELETE) {
        result.delete = takeLeading(CONSTANTS.DELETE, function* deleteData(action) {
            try {
                const data = yield call(requestBearer, API, {
                    method: 'DELETE',
                    headers: headersJson,
                    body: JSON.stringify(action.payload)
                });
                iziToast.success(iziToastDelete);
                yield put(ACTIONS.updateSuccess(data, action.payload));
                action.callback && action.callback();
            } catch (error) {
                yield put(ACTIONS.updateError(error));
                action.callback && action.callback(error);
            }
        })
    }

    if (params) {
        // eslint-disable-next-line
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                result[key] = params[key]
            }
        }
    }
    /**
     * Root saga manages watcher lifecycle
     */
    return function* requestSaga() {
        yield all(result);
    }
}