import createApiActions from '../utils/createApiActions';
import { authConstants } from '../constants/reduxConstants';

function refreshLogin(token) {
    return {
        type: authConstants.REFRESH_LOGIN,
        payload: {
            token
        }
    }
}

function refreshLoginSuccess(payload) {
    return {
        type: authConstants.REFRESH_LOGIN_SUCCESS,
        payload
    }
}

function refreshLoginError(error) {
    return {
        type: authConstants.REFRESH_LOGIN_ERROR,
        error
    }
}

function logout() {
    return { type: authConstants.LOGOUT }
}

function logoutSuccess() {
    return { type: authConstants.LOGOUT_SUCCESS }
}

function login({ username, password }) {
    return {
        type: authConstants.LOGIN,
        payload: {
            username,
            password
        }
    }
}

function loginSuccess(payload) {
    return {
        type: authConstants.LOGIN_SUCCESS,
        payload
    }
}

function loginError(error) {
    return {
        type: authConstants.LOGIN_ERROR,
        error
    }
}

function changePassword(payload) {
    return {
        type: authConstants.CHANGE_PASSWORD,
        payload
    }
}

function changePasswordSuccess(payload) {
    return {
        type: authConstants.CHANGE_PASSWORD_SUCCESS,
        payload
    }
}

function changePasswordError(payload) {
    return {
        type: authConstants.CHANGE_PASSWORD_ERROR,
        payload
    }
}

function readUser(payload) {
    return {
        type: authConstants.READ_USER,
        payload
    }
}

function updateUser(payload) {
    return {
        type: authConstants.UPDATE_USER,
        payload
    }
}

function updateUserSuccess(payload) {
    return {
        type: authConstants.UPDATE_USER_SUCCESS,
        payload
    }
}

function updateUserError(payload) {
    return {
        type: authConstants.UPDATE_USER_ERROR,
        payload
    }
}

function readUserSuccess(payload) {
    return {
        type: authConstants.READ_USER_SUCCESS,
        payload
    }
}

function activate(payload) {
    return {
        type: authConstants.ACTIVATE,
        payload
    }
}

function activateSuccess(payload) {
    return {
        type: authConstants.ACTIVATE_SUCCESS,
        payload
    }
}

function activateError(error) {
    return {
        type: authConstants.ACTIVATE_ERROR,
        error
    }
}


const actionExtends = {
    refreshLogin,
    refreshLoginSuccess,
    refreshLoginError,
    loginSuccess,
    loginError,
    login,
    logout,
    logoutSuccess,
    changePassword,
    changePasswordError,
    changePasswordSuccess,
    readUser,
    readUserSuccess,
    activate,
    activateSuccess,
    activateError,
    updateUser,
    updateUserSuccess,
    updateUserError
};

export default createApiActions(authConstants, actionExtends);