import React from 'react';
import './loading-indicator.scss';

const LoadingIndicator = React.memo(props => (
  <div className="loading-indicator">
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
));

export default LoadingIndicator;
