const APP_NAME = 'jaclo';

const DEFAULT_CONSTANTS =  [
    'CREATE',
    'CREATE_SUCCESS',
    'CREATE_ERROR',
    'READ',
    'READ_SUCCESS',
    'READ_ERROR',
    'UPDATE',
    'UPDATE_SUCCESS',
    'UPDATE_ERROR',
    'DELETE',
    'DELETE_SUCCESS',
    'DELETE_ERROR',
];

export default function createApiConstants(PAGE_NAME, subName, params = DEFAULT_CONSTANTS) {
    if(!Array.isArray(params)) {
        params = [
            params,
            `${params}_SUCCESS`,
            `${params}_ERROR`
        ]
    }

    const BASE_NAME = `${APP_NAME}/${PAGE_NAME}`;
    const result = {};

    for (let i = 0; i < params.length; i++) {
        const param = params[i];
        result[param] = `${BASE_NAME}/${subName}/${param}`;
    }
    result.CLEAR = `${BASE_NAME}/${subName}/CLEAR`
    return result
}