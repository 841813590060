/**
 * Homepage selectors
 */

import { createSelector } from 'reselect';

const selectSweetAlert = (state) => state.sweetAlert;

const makeSelectSweetAlert = () => createSelector(
  selectSweetAlert,
  (sweetAlert) =>  sweetAlert
);

export {
  selectSweetAlert,
  makeSelectSweetAlert,
};