/**
 * The global state selectors
 */

import { createSelector } from 'reselect';

const selectRoute = (state) => state.router;

const selectLocation = createSelector(
    selectRoute,
    routeState => routeState.location
)
export {
    selectRoute,
    selectLocation
};
