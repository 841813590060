
/**
 * index.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable'
import { loadState, saveState } from './localStorage';
import FontFaceObserver from 'fontfaceobserver';
//import FontFaceObserver from 'fontfaceobserver';
// import * as serviceWorker from './serviceWorker';

// Import root app
import App from 'containers/App';

// Import CSS reset and Global Styles
import './styles/index.scss';
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

import configureStore, { history }  from './configureStore';

if (process.env.NODE_ENV !== 'development') {
  let href = window.location.href;
  if(href.includes('http://')) {
    href = href.replace('http://','https://');
    window.location = href;
  }
}

// Observe loading of Nunito (to remove Nunito, remove the <link> tag in
// the index.html file and this observer)
const NunitoObserver = new FontFaceObserver('Nunito', {});
// When Nunito is loaded, add a font-family using Nunito to the body
NunitoObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
}, () => {
  document.body.classList.remove('fontLoaded');
});


// ========================================================
// Developer Tools Setup
// ========================================================
// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js');
//   whyDidYouRender(React, {
//     logOnDifferentValues: false,
//     collapseGroups: true,
//     trackHooks: false,
//     include: [/.*/],
//     // exclude: [/^WaitForContainer/, /^RefHolder/, /^Link/, /^LoadableComponent/, /^Router/, /^HighchartsChart/]
//   });
//   // whyDidYouRender(React, { exclude: [/^WaitForContainer/, /^RefHolder/, /^Link/, /^LoadableComponent/, /^Router/, /^HighchartsChart/] });
// }
// Create redux store with history

// Create redux store with history
const initialState = {
  global: {
    auth: loadState('auth')
  },
  globalAdmin: {
    auth: loadState('authAdmin')
  }
};

const store = configureStore(initialState);

let prevState = initialState;

store.subscribe(() => {
  const state = store.getState();
  if(state.global.auth !== prevState.global.auth) {
    saveState('auth', state.global.auth);
    prevState = state;
  }
  if(state.globalAdmin.auth !== prevState.globalAdmin.auth) {
    saveState('authAdmin', state.globalAdmin.auth);
    prevState = state;
  }
});

const MOUNT_NODE = document.getElementById('DZHAWEB_ADMIN');

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      {/* <LanguageProvider messages={messages}> */}
      <ConnectedRouter history={history}>
          <App />
      </ConnectedRouter>
      {/* </LanguageProvider> */}
    </Provider>,
    MOUNT_NODE
  );
};

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
