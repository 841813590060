import { makeSelect } from 'api/utils/makeSelectors';
import { createSelector } from 'reselect';

const selectTokens = createSelector(
    makeSelect(['globalAdmin','auth', 'payload']),
    (authPayload) => ({
        access_token: authPayload.access_token,
        refresh_token: authPayload.refresh_token,
    })
);

export default selectTokens;