import React from 'react';
import FontAwesomeIcon from 'components/FontAwesomeIcon';
import { Link } from 'react-router-dom';
import Container from 'components/Container';
import Navbar from 'components/Navbar';
import Nav from 'components/Nav';

const domain = window.location.hostname.split('.').slice(-2).join('.');

class Header extends React.Component {

  onLogout = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onLogout();
  }

  render() {
    const {username} = this.props.user;
    return (
      <Container as='header' fluid>
        <div className="navbar-bg"></div>
        <Navbar variant='main' expand='lg'>
          <Link to="/">
            <Navbar.Brand className='h5' > DZHAWEB </Navbar.Brand>
          </Link>
          <Nav.Item>
            <Nav.Link href={`https://${domain}`} className='h5'>
              В партнерку
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href={`https://admin.${domain}`} className='h5'>
              В админку партнерки
            </Nav.Link>
          </Nav.Item>
          <Nav className="ml-auto">

            {username &&
              <Nav.Item>
                <Nav.Link onClick={this.onLogout} className='h5'>
                  Выход{' '}<FontAwesomeIcon icon={'sign-out-alt'} size="lg" />
                </Nav.Link>
              </Nav.Item>
            }
            
          </Nav>
        </Navbar>
      </Container>
    );
  }
}

export default Header;