const hasOwnProperty = Object.prototype.hasOwnProperty;

export default function createApiActions(constants={}, actionExtends={}) {
    
    function checkHandler(handler) {
        return (hasOwnProperty.call(constants,handler) && !hasOwnProperty.call(actionExtends,handler))
    }
    const result = {};
    
    if(checkHandler('CLEAR')) {
        /**
         * Очистить хранилище данных, это действие приводит reducer к исходному значению
         *
         * @return {object} Объект action с параметром type: CLEAR
         */
        result.clear = function clear() {
            return {
                type: constants.CLEAR,
            };
        };
    }

    if(checkHandler('CREATE')) {
        /**
        * Создать данные, это действие отправляет запрос на создание новых данных
        * @param  {object} payload Параметры запроса 
        *
        * @return {object} Объект action с параметром type: CREATE
        */
        result.create = function create(payload, callback) {
            return {
                type: constants.CREATE,
                payload, callback
            };
        };
    }
    
    if(checkHandler('CREATE_SUCCESS')) {
        /**
         * Вызывается в request saga, когда получен положительный ответ от сервера
         * @param  {object} payload Ответ сервера 
         *
         * @return {object} Объект action с параметром type: CREATE_SUCCESS
         */
        result.createSuccess = function createSuccess(payload, initPayload) {
            return {
                type: constants.CREATE_SUCCESS,
                payload, initPayload
            };
        };
    }
    
    if(checkHandler('CREATE_ERROR')) {
        /**
         * Вызывается в request saga, когда сервер ответил ошибкой 
         * @param  {object} payload Ответ сервера 
         *
         * @return {object} Объект action с параметром type: CREATE_ERROR
         */
        result.createError = function createError(payload) {
            return {
                type: constants.CREATE_ERROR,
                payload
            };
        };
    }
    
    if(checkHandler('READ')) {
        /**
        * Отправить запрос на чтение данных
         * @param  {object} payload Параметры запроса
         *
         * @return {object} Объект action с параметром type: READ
         */
        result.read = function read(payload, callback) {
            return {
                type: constants.READ,
                payload, callback
            };
        };
    }
    
    if(checkHandler('READ_SUCCESS')) {
        /**
         * Вызывается в request saga, когда получен положительный ответ от сервера
         * @param  {object} payload Ответ сервера 
         *
         * @return {object} Объект action с параметром type: READ_SUCCESS
         */
        result.readSuccess = function readSuccess(payload, initPayload) {
            return {
                type: constants.READ_SUCCESS,
                payload, initPayload
            };
        };
    }
    
    if(checkHandler('READ_ERROR')) {
        /**
         * Вызывается в request saga, когда сервер ответил ошибкой
         * @param  {object} payload Ответ сервера 
         *
         * @return {object} Объект action с параметром type: READ_ERROR
         */
        result.readError = function readError(payload) {
            return {
                type: constants.READ_ERROR,
                payload
            };
        };
    }

    if(checkHandler('UPDATE')) {
        /**
        * Отправить запрос на обновление данных данные
        * @param  {object} payload Параметры запроса
        *
        * @return {object} Объект action с параметром type: UPDATE
        */
        result.update = function update(payload, callback) {
            return {
                type: constants.UPDATE,
                payload, callback
            };
        };
    }
    
    if(checkHandler('UPDATE_SUCCESS')) {
        /**
         * Вызывается в request saga, когда получен положительный ответ от сервера
         * @param  {object} payload Ответ сервера 
         *
         * @return {object} Объект action с параметром type: UPDATE_SUCCESS
         */
        result.updateSuccess = function updateSuccess(payload, initPayload) {
            return {
                type: constants.UPDATE_SUCCESS,
                payload, initPayload
            };
        };
    }
    
    if(checkHandler('UPDATE_ERROR')) {
        /**
         * Вызывается в request saga, когда сервер ответил ошибкой
         * @param  {object} payload Ответ сервера 
         *
         * @return {object} Объект action с параметром type: UPDATE_ERROR
         */
        result.updateError = function updateError(payload) {
            return {
                type: constants.UPDATE_ERROR,
                payload
            };
        };
    }

    if(checkHandler('DELETE')) {
        /**
         * Отправить запрос на удаление данных
         * @param  {object} payload Параметры запроса 
         *
         * @return {object} Объект action с параметром type: DELETE
         */
        result.delete = function deleteRequest(payload, callback) {
            return {
                type: constants.DELETE,
                payload, callback
            };
        };
    }
    
    if(checkHandler('DELETE_SUCCESS')) {
        /**
         * Вызывается в request saga , когда запрашивается данные
         * @param  {object} payload Ответ сервера 
         *
         * @return {object} Объект action с параметром type: DELETE_SUCCESS
         */
        result.deleteSuccess = function deleteSuccess(payload, initPayload) {
            return {
                type: constants.DELETE_SUCCESS,
                payload, initPayload
            };
        };
    }
    
    if(checkHandler('DELETE_ERROR')) {
        /**
         * Вызывается в request saga, когда загрузка данные произошла с ошибкой 
         * @param  {object} payload Ответ сервера 
         *
         * @return {object} Объект action с параметром type: DELETE_ERROR
         */
        result.deleteError = function deleteError(payload) {
            return {
                type: constants.DELETE_ERROR,
                payload
            };
        };
    }
    
    if(actionExtends) {
        // eslint-disable-next-line
        for (const key in actionExtends) {
            if (actionExtends.hasOwnProperty(key)) {
                result[key] = actionExtends[key]
            }
        }
    }

    return result;
}