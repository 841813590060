const domain = window.location.hostname.split('.').slice(-2).join('.');
let apiBase = `${window.location.protocol}//clo.${domain}`;
if (process.env.NODE_ENV === 'development') {
    apiBase = 'https://clo.dzhaweb.com';
	// apiBase = 'http://localhost:3009';
}

let apiPartherBase = `${window.location.protocol}//partner.${domain}`;
if (process.env.NODE_ENV === 'development') {
	// apiPartherBase = 'http://localhost:8000';
	apiPartherBase = 'https://partner.dzhaweb.com';
}
export const API_PARTNER_BASE = `${apiPartherBase}/api`;

export const API_BASE     = apiBase +'/api';
export const API_PANEL    = API_BASE +'/panel';
export const API_ADMIN    = API_BASE +'/admin';
export const API_PAYMENTS = API_BASE +'/payments';
export const AUTH         = API_BASE +'/auth';
export const AUTH_PARTNER = API_PARTNER_BASE +'/auth';

export const CLIENT_USERNAME = window.CLIENT_USERNAME || 'JACLO_WEB_ADMIN';
export const CLIENT_PASSWORD = window.CLIENT_PASSWORD || 'Secret-JACLO_WEB_ADMIN';

export const PARTNER_CLIENT_USERNAME = 'Web-admin';
export const PARTNER_CLIENT_PASSWORD = 'Secret-Web-admin';

const apiConstants = {
    auth: `${API_BASE}/auth`,
    login: `${AUTH}/login`,
    adminLogin: `${AUTH}/adminLogin`,
    adminPartnerLogin: `${AUTH_PARTNER}/adminLogin`,
    logout: `${AUTH}/logout`,
    changePassword: `${AUTH}/changePassword`,
    activate: `${AUTH}/getActivate`,

    tariffs: `${API_ADMIN}/tariff`,
    users: `${API_ADMIN}/users`,
    usersTariff: `${API_ADMIN}/users/tariff`,
};

export default apiConstants;