import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';

// import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
// import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
// import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation';
// import { faFire } from '@fortawesome/free-solid-svg-icons/faFire';
// import { faSuitcase } from '@fortawesome/free-solid-svg-icons/faSuitcase';
// import { faPlusSquare } from '@fortawesome/free-solid-svg-icons/faPlusSquare';
// import { faListUl } from '@fortawesome/free-solid-svg-icons/faListUl';
// import { faUserCheck } from '@fortawesome/free-solid-svg-icons/faUserCheck';
// import { faUserAltSlash } from '@fortawesome/free-solid-svg-icons/faUserAltSlash';
// import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
// import { faRobot } from '@fortawesome/free-solid-svg-icons/faRobot';
// import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
// import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
// import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
// import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
// import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
// import { faTasks } from '@fortawesome/free-solid-svg-icons/faTasks';
// import { faSlidersH } from '@fortawesome/free-solid-svg-icons/faSlidersH';
// import { faFileContract } from '@fortawesome/free-solid-svg-icons/faFileContract';
// import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
// import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
// import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
// import { faFileDownload } from '@fortawesome/free-solid-svg-icons/faFileDownload';
// import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
// import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
// import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
// import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
// import { faLayerGroup } from '@fortawesome/free-solid-svg-icons/faLayerGroup';
// import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard';
// import { faReceipt } from '@fortawesome/free-solid-svg-icons/faReceipt';
// import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion';
// import { faChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar';
// import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
// import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
// import { faRubleSign } from '@fortawesome/free-solid-svg-icons/faRubleSign';
// import { faBolt } from '@fortawesome/free-solid-svg-icons/faBolt';
// import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
// import { faUserCog } from '@fortawesome/free-solid-svg-icons/faUserCog';

import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';

library.add(
    // faBars,
    // faBell,
    // faExclamation,
    // faFire,
    // faSuitcase,
    // faPlusSquare,
    // faListUl, 
    // faUserCheck, 
    // faUserAltSlash, 
    // faRobot,
    // faSearch,
    // faChevronLeft,
    // faChevronRight,
    // faTasks, 
    // faSlidersH, 
    // faFileContract,
    // faArrowRight,
    // faArrowLeft,
    // faTimesCircle,
    // faFileDownload,
    // faBan,
    // faPlay,
    // faPencilAlt,
    // faTrash,
    // faLayerGroup,
    // faCreditCard,
    // faReceipt,
    // faQuestion,
    // faChartBar,
    // faTimes,
    // faCheck,
    // faRubleSign,
    // faBolt,
    // faChartLine,
    // faChevronDown,
    // faChevronUp,
    // faUserCircle,
    // faUserCog,
    faUserPlus,
    faUsers,
    faSignOutAlt
)

export {
    FontAwesomeIcon
};

const ReactFontAwesomeIcon = React.memo((props)=> (<FontAwesomeIcon {...props}/>));

export default ReactFontAwesomeIcon;