/**./ErrorPage
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */

import React from 'react';
import Cat from 'assets/images/cat.png';
import Img from 'components/Image';

export default React.memo(({error={}})=> {
  return (
    <div className="page-error pt-0">
      <div className="page-inner">
        <h1>
          <Img fluid src={Cat} alt='408'/>
        </h1>
        <h3>Во время загрузки страницы произошла ошибка!</h3>
        <div className="page-description">
          Почистите кэш браузера и обновите страницу.
          {error.message}
        </div>
      </div>
    </div>
  )
})