// import { all,} from 'redux-saga/effects';
// import { getBaseSaga } from './utils';
// import actions from '../actions';
// import * as constants from '../constants';
// import * as API from '../apiConst';

// import auth from './auth';
// import authAdmin from './authAdmin';

// const sagaExtends = {}

// const allSagas = Object.create(null);

// for (const key in constants) {
//     const saga = getBaseSaga(constants[key], API[key], actions[key], sagaExtends[key]);
//     allSagas[key] = saga();
// }

// allSagas['authAdmin'] = authAdmin();
// allSagas['auth'] = auth();

// export default function* global() {
//     yield all(Object.values(allSagas))
// }


import { all } from 'redux-saga/effects';
import createApiSaga from '../utils/createApiSaga';

import actions from '../actions';
import reduxConstants from '../constants/reduxConstants';
import apiConstants from '../constants/apiConstants';

import authSaga from './auth';
import authAdminSaga from './authAdmin';

const allSagas = {
    auth: authSaga(),
    authAdmin: authAdminSaga()
};
// eslint-disable-next-line
for (const key in reduxConstants) {
    if(!allSagas[key]) {
        const saga = createApiSaga(reduxConstants[key], apiConstants[key], actions[key]);
        allSagas[key] = saga();
    }
}

export default function* global() {
    yield all(Object.values(allSagas))
}