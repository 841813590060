/*
 * SweetAlertReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import {
  SHOW,
  CLOSE,
} from './constants';

const initialState = {
  show: false,
  title: '',
  onConfirm: ()=>{}
};

const ACTION_HANDLER = {
  [SHOW]: (state, { payload }) => ({
    show: true,
    ...payload,
  }),
  [CLOSE]: () => initialState,
};

export default function extendReduser(state=initialState, action){
  const handler = ACTION_HANDLER[action.type];
  return handler ? handler(state, action) : state;
}