/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route } from 'react-router-dom';
import Container from 'components/Container';
import Header from './Header';
import Footer from './Footer';
import HomePage from 'containers/HomePage/Loadable';
import NotFoundPage from 'containers/NotFoundPage';
import LoginPage from 'containers/LoginPage/Loadable';
import SweetAlert from 'components/SweetAlert';
export default class App extends React.Component {

  componentDidMount() {
    // if(!this.props.auth.isAuth && (this.props.location.pathname.indexOf('login') === -1)) {
    //   window.location = '/login';
    // }
    this.checkAdmin()
  }
  componentDidUpdate() {
    this.checkAdmin()
  }

  checkAdmin() {
    // if(this.props.auth.isAuth) {
    //   const user = this.props.auth.payload.user;
    //   if(!user.access.admin) {
    //     window.location = '/'
    //   }
    // }
  }

  render() {
    return (
      <React.Fragment>
        <Helmet
          titleTemplate="%s - Admin DZHAWEB"
          defaultTitle="Admin DZHAWEB"
        />
        {/* <Sidebar currentPage={this.props.location.pathname} /> */}
        <div className="main-content no-sidebar">
          <Header />
          <Container fluid as="main">
            {this.props.auth.isAuth ? (
              <Switch location={this.props.location}>
                <Route exact path="/">
                  <HomePage/>
                </Route>
                <Route path="">
                  <NotFoundPage/>
                </Route>
              </Switch>
            ) : (
                <LoginPage />
              )}
          </Container>
        </div>
        <Footer fluid/>
        <SweetAlert/>
      </React.Fragment>
    )
  }
}
