import createApiActions from '../utils/createApiActions';
import { authAsUserConstants } from '../constants/reduxConstants';

function login(payload) {
    return {
        type: authAsUserConstants.LOGIN,
        payload
    }
}

function loginSuccess(payload) {
    return {
        type: authAsUserConstants.LOGIN_SUCCESS,
        payload
    }
}

function loginError(error) {
    return {
        type: authAsUserConstants.LOGIN_ERROR,
        error
    }
}

function loginPartner(payload) {
    return {
        type: authAsUserConstants.LOGIN_PARTNER,
        payload: payload
    }
}

function loginPartnerSuccess(payload) {
    return {
        type: authAsUserConstants.LOGIN_PARTNER_SUCCESS,
        payload
    }
}

function loginPartnerError(error) {
    return {
        type: authAsUserConstants.LOGIN_PARTNER_ERROR,
        error
    }
}

const actionExtends = {
    loginSuccess,
    loginError,
    login,
    loginPartner,
    loginPartnerSuccess,
    loginPartnerError,
};

export default createApiActions(authAsUserConstants, actionExtends);